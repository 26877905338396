<template>
  <div>
    <div class="page-header clear-filter">
      <!--style="background-image:url('img/pippers_on_the_field.jpg');background-repeat: no-repeat;"-->
      <parallax
          class="page-header-image"
          style="background-color: darkgreen; background-image:url('img/pippers_on_the_field.jpg');background-size: 75%;background-blend-mode: lighten; background-repeat: no-repeat;"
      >

      </parallax>
      <div class="container">

        <div class="h1-seo"></div>
        <div class="content-center brand">
         <!--<parallax class="page-header-image" style="background-image: url('img/pippers_on_the_field.jpg')"></parallax>-->
          <b-card>
            <b-card-header> <img  src="img/logo.jpg" style="width:200px;" alt="" /></b-card-header>
            <b-card-text>
              <h3 style="font-family: 'irish-uncialfabeta'"><b>Finger Lakes Celtic Society Inc</b></h3>
              <h3>Welcome to the Home of The Finger Lakes Celtic Games & Festival</h3>
              <!--Start PreEvent Text -->
              <br>
              <p><b style="color: #e95e38">It is with sad hearts, sincere thanks and appreciation for all those who have helped with presentation of the Finger Lakes Celtic Games and Festival over the past 15 years.
              We unfortunately now  must announce the conclusion of the Finger Lakes Celtic Festival.   With many ongoing issues we have finally come to the conclusion there is no possible way we can continue to make this event happen anymore.
              Your support has been greatly appreciated.
              We announce this with thanks and love for all who helped, participated and attended over the years.

              Sincerely,
              Finger Lakes Celtic Society</b></P>
               <!-- <br><b><a href="https://tickets.lincolnhillfarms.com/lincolnhillfarms/events/018cd5bd-e4a5-aed6-ad21-3b764d4084a4?fbclid=IwAR2r2WawAvHvrXpfZyF3rX8QjKaz5HTxemyL9pxKHM5yIuyiy59jm6r9kyA" style="color: #cc2127" target="_blank">Click Here For Advanced Tickets!</a></b> -->
              <!--End PreEvent Text -->
              <!--Start PostEvent Text -->
              <!--<br><h3 style="font-family: 'irish-uncialfabeta'"><b>We Look Forward To Seeing Everybody Again In 2024!!</b></h3>
              <br><b>Saturday, May 18th 2024</b>
              <br><b>9:30AM - 7PM</b>-->
              <!-- End PostEvent Text -->
              <p><b> The following information and location pertained to our 2024 festival:</b></p>
              <p>Come be Celtic for the day (if you’re not already) and enjoy a pleasing array of sights, sounds and smells.
                <br>This event includes: Highland Heavy Games, Bag Piping and Massed Bands, Dance Exhibitions, an array of Celtic Music, Demonstrations, A large Gathering of Scottish Clans, Historical & Heritage Societies, Artisans, Kids Activities, and so much more.
                <br>Admission fee, no pets, free parking and it’s happening Rain or Shine.
                <br><b>Presented In Partnership With</b>
                  <br><b>Lincoln Hill Farm, 3792 NY247, Canandaigua, NY 14424</b></p>
            </b-card-text>
          </b-card>
          <!--<b-card>
            <b-card-text>
              <h3 style="font-family: 'irish-uncialfabeta'"><b>Event Schedule</b></h3>
              <div style="width: fit-content; text-align: center;">
              <ul style="text-align: left; list-style-position: inside; list-style-type: disc;  margin-left: 1.5em;
  padding-left: 0; width: fit-content;">
                <b><li>Gates Open 9:30am</li>
                <li>Highland Games at 9:30am</li>
                <li>Our Headlining Band '1916' at 6pm  <a href="https://www.1916band.com/" target="_blank">visit website</a></li>
                <li> Highland Heavy Games (all 9 events); 9:30am-4:30pm</li>
                <li>Bag Pipes (8 bands); 10-5</li>
                <li>Demonstrations (various locations); all day</li>
                <li>Vendors & Artisans 9:30am-7:30pm</li>
                <li>Kids Activities; 10-6</li>
                <li>Celtic Music & Dance; starts when gates open and goes till 7:30pm</li>
                <li>Opening Ceremonies & Mass Bands 11am</li>-->
                <!--<li>Main Stage at 6pm</li>
                <li>
                  Main Stage Performances:
                  <ul>
                    <li>Comhaltas Society at 9:25am</li>
                    <li>Clan Tankerous at 10:30am</li>
                    <li>Opening Ceremonies at 11:30am</li>
                    <li>Dungarees at 12:30pm</li>
                    <li>May Pole (nearby) at 1:30pm</li>
                    <li>Everheart at 2:00pm</li>
                    <li>Lucky Enough at 3:00pm</li>
                    <li>Old World Warblers at 4:00pm</li>
                    <li>Brass Spittoon Band at 5:00pm</li>
                    <li>Awards/Closing at 6:00pm</li>
                    <li>Himself at 6:30pm</li>
                  </ul>
                </li>
                <li>
                  Tent Stage Performances:
                  <ul>
                    <li>Talon at 9:30am and 10:30am</li>
                    <li>Gracie &amp; Karl Celtic Show at 10:00am</li>
                    <li>Adirondack Mountain Cloggers at 11:00am</li>
                    <li>Opening Ceremonies at 11:30am</li>
                    <li>Jamison Irish Dance at 12:30pm and 1:30pm</li>
                    <li>Doolin O'Dey at 1:00pm and 2:00pm</li>
                    <li>ROCeltic at 2:30pm and 3:30pm</li>
                    <li>Kanack Fiddlers at 3:00pm</li>
                    <li>Step in Time at 4:00pm and 6:00pm</li>
                    <li>Syracuse Scottish Dance at 4:30pm and 5:30pm</li>
                    <li>Rakes in the Rye at 5:00pm</li>
                  </ul>
                </li>-->

               <!-- <li>
                  Pipe Bands:
                  <ul>
                    <li>Buffalo Niagara Scotia Society Pipes and Drums</li>
                    <li>Caledonian Pipe Band</li>
                    <li>Feadan Or</li>
                    <li>Gates Keystone Club Police Pipes and Drums</li>
                    <li>MacKenzie Highlanders</li>
                    <li>Rochester Scottish</li>
                  </ul>
                </li></b>
              </ul>
              </div>
            </b-card-text>
          </b-card>-->
          <google-map></google-map>
          <b-card></b-card>
          </div>



      </div>
    </div>
  </div>
</template>
<script>
import { Parallax } from '@/components';
import GoogleMap from './components/GoogleMap';
//import CarouselSection from './components/CarouselSection';
export default {
  name: 'starter',
  bodyClass: 'index-page',
  components: {
    Parallax,
    GoogleMap
    //BasicElements,
    //Navigation,
    //TabsSection,
    //ProgressPagination,
    //Notifications,
    //Typography,
    //JavascriptComponents,
   // CarouselSection //,
    //NucleoIconsSection,
    //SignupForm,
    //ExamplesSection,
    //DownloadSection
  }
};
/*
import {videoPlayer} from 'vue-videojs7';
export ViedoPlayer {
  components: {
    videoPlayer
  }
}
 */

</script>
<style>
.starter-page {
  min-height: calc(100vh - 95px);
}
</style>
