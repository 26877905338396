<template>
  <div>
    <div class="page-header clear-filter">
      <parallax
          class="page-header-image"
          style="background-color: darkgreen; background-image:url('img/pippers_on_the_field.jpg');background-size: 75%;background-blend-mode: lighten; background-repeat: no-repeat;"
      >
      </parallax>
      <div class="container">
        <div class="h1-seo"></div>
        <div class="content-center brand">
          <b-card-group style="margin-top: 20px;">
            <b-card-header> <img  src="img/logo.jpg" style="width:200px;" alt="" /></b-card-header>
            <b-card><p><b style="color: #e95e38">Please note, as stated on our home page, the festive has been canceled for the foreseeable future.</b></P></b-card>
            <b-card>
              <b-card-header>By Mail</b-card-header>
              <b-card-body>Finger Lakes Celtic Society Inc<br>4925 Collett Road<br>Farmington, NY 14548</b-card-body>
            </b-card>
            <b-card>
              <b-card-header>By E-Mail</b-card-header>
              <b-card-body>flceltic@yahoo.com</b-card-body>
            </b-card>
            <b-card>
              <b-card-header>On Facebook</b-card-header>
              <b-card-body><a
                  class="nav-link"
                  rel="tooltip"
                  title="Follow us on Facebook"
                  data-placement="bottom"
                  href="https://www.facebook.com/Fingerlakesceltic"
                  target="_blank"
              >
                <i class="fab fa-facebook-square"></i>
                <p class="d-lg-none d-xl-none">Facebook</p>
              </a><a href="https://www.facebook.com/Fingerlakesceltic">https://www.facebook.com/Fingerlakesceltic</a> </b-card-body>
            </b-card>
          </b-card-group>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import { Parallax } from '@/components';
export default {
  name: 'ContactUs',
  bodyClass: 'index-page',
  components: {
    Parallax //,
    //BasicElements,
    //Navigation,
    //TabsSection,
    //ProgressPagination,
    //Notifications,
    //Typography,
    //JavascriptComponents,
    //CarouselSection,
    //NucleoIconsSection,
    //SignupForm,
    //ExamplesSection,
    //DownloadSection
  }
};
</script>