<template>
  <div>
    <div class="page-header clear-filter">
      <parallax
          class="page-header-image"
          style="background-color: darkgreen; background-image:url('img/pippers_on_the_field.jpg');background-size: 75%;background-blend-mode: lighten; background-repeat: no-repeat;"
      >
      </parallax>
      <div class="container">
        <div class="h1-seo"></div>
        <div class="content-center brand">
          <b-card>
            <b-card-header> <img  src="img/logo.jpg" style="width:200px;" alt="" /></b-card-header>
            <b-card-text><p><b style="color: #e95e38">Please note, as stated on our home page, the festive has been canceled for the foreseeable future.</b></P></b-card-text>
            <!--<b-carousel><carousel-section></carousel-section></b-carousel>-->
            <ThumbnailGrid />
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import CarouselSection from './components/CarouselSection';
import ThumbnailGrid from './components/ThumbnailGrid.vue';
import {Parallax} from "@/components";
/*export default {
  name: "PhotoGallery",
  bodyClass: 'index-page',
  components: {
    CarouselSection
  }
}*/
export default {
  name: "PhotoGallery",
  bodyClass: 'index-page',
  components: {
    ThumbnailGrid
  }
};
</script>

<style scoped>

</style>